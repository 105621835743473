import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/cellusys-technologies/app/src/client/template.js";
import SymbolSvg from "../assets/images/pages/home/symbol.inline.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Block = makeShortcode("Block");
const AnimateMap = makeShortcode("AnimateMap");
const SiteLogo = makeShortcode("SiteLogo");
const Navigation = makeShortcode("Navigation");
const Anchor = makeShortcode("Anchor");
const Heading = makeShortcode("Heading");
const Paragraph = makeShortcode("Paragraph");
const Carousel = makeShortcode("Carousel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row id="welcome" mdxType="Row">
  <Block id="map" mdxType="Block">
    <AnimateMap mdxType="AnimateMap" />
  </Block>
  <Block id="header" mdxType="Block"><SiteLogo mdxType="SiteLogo" /></Block>
  <Block id="site-navigation" mdxType="Block"><Navigation mdxType="Navigation">
    <Anchor elementId="all-products" mdxType="Anchor">Products</Anchor>
    <Anchor elementId="all-services" mdxType="Anchor">Services</Anchor>
    <Anchor elementId="contact" mdxType="Anchor">Contact</Anchor>
  </Navigation></Block>
  <Block id="tagline" mdxType="Block">
    <Heading level={2} mdxType="Heading">
      We create and deliver systems to make your mobile network more secure intelligent and profitable
    </Heading>
  </Block>    
    </Row>
    <Row id="all-products" mdxType="Row">
  <Block id="protect" mdxType="Block">
  <div className="parallax"><Heading level={3} mdxType="Heading">Protect</Heading></div>
  <div className="products">
  <div className="product">
    <Heading level={4} mdxType="Heading">Unified Signalling Firewall</Heading>
    <Paragraph mdxType="Paragraph">A comprehensive cross-protocol signalling security solution which protects your network against malicious attacks in every layer, and in every message.</Paragraph>
    <Paragraph mdxType="Paragraph">SS7 / Diameter / GTP / SIP / SMPP</Paragraph>
  </div>
  <div className="product">
    <Heading level={4} mdxType="Heading">SMS Firewall</Heading>
    <Paragraph mdxType="Paragraph">Protection against SMS spam, fraud, and other threats.</Paragraph>
    <Paragraph mdxType="Paragraph">A solution for A2P monetisation.</Paragraph> 
    <Paragraph mdxType="Paragraph">SMS Analysis / SMS Home Router</Paragraph> 
  </div>
  </div>
  </Block>
  <Block id="insight" mdxType="Block">
  <div className="parallax"><Heading level={3} mdxType="Heading">Insight</Heading></div>
  <div className="product">
    <Heading level={4} mdxType="Heading">Mobile Broadband Analytics</Heading>
    <Paragraph mdxType="Paragraph">Near-real time analytics over the full data user and control planes in any network.</Paragraph>
    <Paragraph mdxType="Paragraph">Performance Monitoring / Outbound Roamer Optimisation</Paragraph>
  </div>
  </Block>
  <Block id="roaming" mdxType="Block">
  <div className="parallax"><Heading level={3} mdxType="Heading">Roaming</Heading></div>
  <div className="product">
    <Heading level={4} mdxType="Heading">Precision Roaming Solutions</Heading>
    <Paragraph mdxType="Paragraph">Hit more targets on the basis of traffic, without compromising subscriber quality of service.</Paragraph>
    <Paragraph mdxType="Paragraph">Steering of Roaming / SMS Outreach /<br /> Anti-Steering Detection</Paragraph> 
  </div>
  </Block>
  <Block id="iot" mdxType="Block">
  <div className="parallax"><Heading level={3} mdxType="Heading">IoT</Heading></div>
  <div className="product">
    <Heading level={4} mdxType="Heading">IoT</Heading>
    <Paragraph mdxType="Paragraph">A suite of products for MVNO’s and Enterprises.</Paragraph>
    <Paragraph mdxType="Paragraph">Protection / Analytics / Roaming Control </Paragraph> 
  </div>
  </Block>  
    </Row>
    <Row id="all-services" mdxType="Row">
  <SymbolSvg mdxType="SymbolSvg" />
  <Block mdxType="Block">
    <Heading level={3} mdxType="Heading">Services</Heading>
  </Block>
  <Block id="services" mdxType="Block">
  <Carousel flickityProps={{
          options: {
            freeScroll: false,
            groupCells: 2,
            contain: true,
            prevNextButtons: false
          }
        }} className="carousel-two" mdxType="Carousel">
    <div id="managed-service" className="service">
      <Heading level={4} mdxType="Heading">Managed Service</Heading>
      <Paragraph mdxType="Paragraph">Let our experts manage the day-to-day operation and maintenance of your firewall: signalling security and A2P monetisation.</Paragraph>
    </div>  
    <div id="network-security-assessment" className="service">
      <Heading level={4} mdxType="Heading">Network Security Assessment</Heading>
      <Paragraph mdxType="Paragraph">Cross-protocol penetration test to discover core network security vulnerabilities.</Paragraph>
    </div>
    <div id="sms-grey-route-testing" className="service">
      <Heading level={4} mdxType="Heading">SMS Grey Route Testing</Heading>
      <Paragraph mdxType="Paragraph"> Best in class SMS penetration test to discover A2P SMS leakage under current network conditions.</Paragraph>
    </div>
    <div id="telecom-training" className="service">
      <Heading level={4} mdxType="Heading">Telecom Training</Heading>
      <Paragraph mdxType="Paragraph">Free webinars and customised training for every level of telecom knowledge.</Paragraph>
    </div>
  </Carousel>
  <Carousel flickityProps={{
          options: {
            freeScroll: false,
            groupCells: 1,
            contain: true,
            prevNextButtons: false
          }
        }} className="carousel-one" mdxType="Carousel">
    <div id="managed-service" className="service">
      <Heading level={4} mdxType="Heading">Managed Service</Heading>
      <Paragraph mdxType="Paragraph">Let our experts manage the day-to-day operation and maintenance of your firewall.</Paragraph>
    </div>  
    <div id="network-security-assessment" className="service">
      <Heading level={4} mdxType="Heading">Network Security Assessment</Heading>
      <Paragraph mdxType="Paragraph">Cross-protocol penetration test to discover core network security vulnerabilities.</Paragraph>
    </div>
    <div id="sms-grey-route-testing" className="service">
      <Heading level={4} mdxType="Heading">SMS Grey Route Testing</Heading>
      <Paragraph mdxType="Paragraph"> Best in class SMS penetration test to discover A2P SMS leakage under current network conditions.</Paragraph>
    </div>
    <div id="telecom-training" className="service">
      <Heading level={4} mdxType="Heading">Telecom Training</Heading>
      <Paragraph mdxType="Paragraph">Free webinars and customised training for every level of telecom knowledge.</Paragraph>
    </div>
  </Carousel>
  </Block>
    </Row>
    <Row id="contact" mdxType="Row">
      <Block mdxType="Block">
  <div id="call-to-action">
  <Heading level={2} mdxType="Heading">How can Cellusys help secure, analyse, and monetise your network?</Heading>
  <div className="description">
  <Paragraph mdxType="Paragraph">Contact us for customised solutions.</Paragraph>
  <Paragraph mdxType="Paragraph">
  Network Protection / Anti-Fraud / A2P Monetisation / Revenue Optimisation
  </Paragraph>
  </div>
  <Navigation className="contact-details" mdxType="Navigation">
  <Anchor address="Cellusys Technologies FZE, 59A DTEC, Technohub,
Silicon Oasis, Dubai, UAE" mdxType="Anchor">Cellusys Technologies FZE, 59A DTEC, <br />Technohub,
Silicon Oasis, Dubai, UAE</Anchor>
  <Anchor email="info@cellusys.com" mdxType="Anchor">dubai@cellusys.com</Anchor>
  <Anchor telephone="+971568632574" mdxType="Anchor">+971 568632574</Anchor>
  </Navigation>
  <Heading level={3} id="final-pitch" mdxType="Heading">Serving over 750 million subscribers worldwide
  </Heading>
  </div>
      </Block>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      